import {React, useEffect} from 'react';
import { Button, Grid } from '@mui/material';
import { ExitToApp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import configParam from '../../config';

const LogoutButton = (props) => {
  const ApiURL = configParam.API_URL;
  const navigate = useNavigate();
 
  const clearLocalStorage = () => {
    localStorage.removeItem("user")
    localStorage.removeItem("accesstoken")
    localStorage.removeItem("refreshtoken")

  }
  useEffect(()=>{

const logoutaccess=async()=>{
  clearLocalStorage();
const url='https://uat.cloudgateway.saint-gobain.com/openam/XUI#logout&realm=AccessManagement&goto=https://dev-nis2.0.saint-gobain.com/login';
window.location.href=url
}
// const loginUrl = 'http://localhost:3000/login';
// if(window.location.href===url){
//   window.location.href = loginUrl
// }
// window.location.href='http://localhost:3044/login'
logoutaccess()
  },[])

  const handleLogout = () => {

 
    // Perform other logout actions as needed
  };

  

  return (
    <>
    </>
    // <Grid container direction="column" alignItems="center" spacing={2}>
    //   <Grid item>
    //     <Button
    //       variant="contained"
    //       color="primary"
    //       startIcon={<ExitToApp />}
    //       onClick={handleLogout}
    //     >
    //     Logout lll ({props.userDetails.name}) {/* Display user's name */}
    //     </Button>
    //   </Grid>
    //   <Grid item>
        
    //   </Grid>
    // </Grid>
  );
};

export default LogoutButton;
