import React, { Suspense,  useState } from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { createTheme, CssBaseline, LinearProgress,ThemeProvider } from '@mui/material';
import Login from './pages/login/Login';
import routes from './routes';
import './App.css';
import NisTemplate from './layouts/NisTemplate';
import { AuthContext } from './Context';
import RouteMissing from './RouteMissing';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import configParam from './config';

export const switchRoutes = (
  <React.Fragment>
    {routes.mainRoutes.map((route, index) => {
      return route.component ? (
        <Route path={route.path} name={route.name} element={<route.component />} key={route.name} />
      ) : (
        <React.Fragment key={route.name}></React.Fragment>
      );
    })}

    <Route path="/" element={<Navigate replace to="/login" />} />
  </React.Fragment>
);

const PrivateOutlet = () => {
  const currentUser = localStorage.getItem("user");
  const user = JSON.parse(JSON.stringify(currentUser));
  return user ? <NisTemplate /> : <Navigate to="/login" />;
};


function App() {
  const ApiURL = configParam.API_URL;
  const theme = createTheme({
    typography: {
      fontFamily: `'Nunito Sans'`,
    },

  });

  const currentUser = localStorage.getItem("user");
  const user = JSON.parse(JSON.stringify(currentUser));
  const [userId, _setUserId] = useState(user);
  if (!localStorage.getItem('refreshflag')) {
    // If it doesn't exist, set it to "false"
    localStorage.setItem('refreshflag', 'false');
  }
  const _check_and_get_token = async () => {
    try {
      const existingToken = localStorage.getItem('accesstoken');
      const refreshToken = localStorage.getItem('refreshtoken');

      if (!existingToken || !refreshToken) {
        console.warn('No access or refresh token available.');

        // Check if the user is on the login page
        if (window.location.pathname !== '/login') {
          // Redirect to login if not already there
          console.log('Redirecting to login page');
          window.location.href = '/login';
        }
        return ''; // Exit early, do not proceed further
      }

     
const decodedToken = jwtDecode(existingToken);
      const expirationTime = decodedToken.exp * 1000; // convert to milliseconds
      const timeLeft = expirationTime - Date.now();

      // If the token is about to expire (within 2 minutes), refresh it
      if (timeLeft < 2 * 60 * 1000) {
        const response = await axios.post(ApiURL+'/refresh', { refreshToken });

        const result = response.data;

        // Check if the response contains a valid accessToken
        if (result.accessToken) {
          localStorage.setItem('accesstoken', result.accessToken);
          localStorage.setItem('refreshflag', 'true');
          return result.accessToken;
        } else {
          console.error('Failed to refresh token. Logging out.');
          _logout();
          return '';
        }
      } else {
        return existingToken;
      }
    } catch (e) {
      console.error('Error during token refresh:', e.response?.data || e.message);
      _logout(); // Auto-logout on refresh failure
      return '';
    }
  };

  // Logout function to clear tokens and redirect to login
  const _logout = () => {
    localStorage.removeItem("user")
    localStorage.removeItem("accesstoken")
    localStorage.removeItem("refreshtoken")
    window.location.href = '/login'; // Redirect to login page
  };

  // Start token checking immediately on page load
  (async () => {
    await _check_and_get_token(); // Check token right when the user loads the page
  })();

  // Use an interval to refresh token periodically
  setInterval(async () => {
    await _check_and_get_token();
  }, 180000); // 10 minutes


  return (
    <ThemeProvider theme={theme}>
      <AuthContext.Provider value={{ userId, setAuthTokens: _setUserId }}>
        <CssBaseline />
        <BrowserRouter>
          <Suspense fallback={<LinearProgress />}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path ="*" element={<RouteMissing />} />
              <Route path="/" element={<PrivateOutlet />}>
                {switchRoutes}
              </Route>
            </Routes>
          </Suspense>
        </BrowserRouter>
        </AuthContext.Provider>
    </ThemeProvider>
  );
}

export default App;

