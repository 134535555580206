import React, { useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { viewedReportDataState } from '../../../../recoil/atoms';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const Introduction = ({ formData, setFormData }) => {

  const { IntroValue } = formData;

  const [viewedReportData, setViewedReportData] = useRecoilState(viewedReportDataState);

  useEffect(() => {
    if (viewedReportData.introduction !== undefined && viewedReportData.introduction !== '') {
      setFormData({ ...formData, IntroValue: viewedReportData.introduction });
    }
  }, [viewedReportData.introduction, IntroValue]);

  const handleEditorChange = (field, editor) => {
    let content = editor.getData();

    // Remove automatic hyperlinks
    content = content.replace(/<a[^>]*>.*?<\/a>/gi, '');
    const updatedViewedReportData = { ...viewedReportData, introduction: content };
    setViewedReportData(updatedViewedReportData);
    setFormData({ ...formData, IntroValue: content });

  };
  const editorConfig = {

    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'undo',
      'redo',
    ],
    // Add other configuration options as needed

  };
  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        config={editorConfig}
        data={IntroValue}
        onChange={(event, editor) => {
          handleEditorChange('editorContent', editor); // Update content in state on change
        }}
      />
    </div>
  );
};

export default Introduction;


